import tw, { styled } from "twin.macro"

const StyledOurTeam = styled.section`
  ${tw`md:py-8 py-6`};
  .main-container {
    ${tw`flex flex-col 2xl:px-[160px] sm:px-[50px] px-[10px] mx-auto z-[1] relative gap-x-16 items-center justify-center`}

    .wrapper {
      ${tw`w-full flex md:flex-row flex-col md:gap-8 gap-6`}

      .left, .right {
        ${tw`md:w-1/2 w-full`}
      }

      .left {
        img {
          ${tw`w-full`}
        }
      }

      .right {
        ${tw`flex flex-col gap-4 self-end`}

        h2 {
          ${tw`flex  md:(mb-8 text-4xl) mb-4 text-2xl relative font-bold`}

          &:after {
            ${tw`[content: ''] absolute h-1 md:-bottom-4 -bottom-2 bg-secondary left-0 right-0 w-24 rounded-xl`}
          }
        }

        p {
          ${tw`text-white/90 font-medium`}
        }
      }
    }
  }
`

export default StyledOurTeam

import React from "react"

import StyledDescription from "./style/description.js"

const Description = () => {
  return (
    <StyledDescription>
      <h2>Working at Cresol means flexibility, trust, growth, and respect.</h2>
      <p>
        We want to continuously learn and improve so that we maintain a
        feedback-oriented environment. Most importantly we care about innovative
        sustainable software, how our activity affects the world, and each
        individual employee’s experience. As a certified organization, we offer
        a safe, comprehensive, and productive environment to all team members,
        we’re Always open to your feedback accept, and move further.
      </p>
    </StyledDescription>
  )
}

export default Description

import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Banner from "../components/careers/Banner"
import OurTeam from "../components/careers/OurTeam"
import Details from "../components/careers/Details"
import Header from "../components/common/Header"
import Description from "../components/careers-list/Description"
import MetaTags from "react-meta-tags"
import SEO from "../components/seo"

export const pageQuery = graphql`
  query HomeQuery {
    careersGroup: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "career-details" } } }
    ) {
      group(field: frontmatter___categories) {
        fieldValue
      }
    }
  }
`

const CareersList = ({ data }) => {
  const { careersGroup } = data
  const list = careersGroup

  return (
    <Layout>
      <SEO
        title="Careers at Cresol Infoserv"
        description="Join a team of innovators and digital experts at Cresol Infoserv. Explore exciting career opportunities and take your professional journey to new heights."
        keywords="best website development company, website design and development services, best digital marketing services, software testing services, mobile app development company"
      />
      {/* <Banner /> */}
      <Header
        title="Careers"
        description="Pilot your career in the right direction, Build your profession that suits your lifestyle, in a company where your voice matters. Let's build a livable world together"
      />
      <Description />
      {/* <OurTeam /> */}
      {list && <Details data={list} />}
    </Layout>
  )
}

export default CareersList

import tw, { styled } from 'twin.macro';

const StyledCta = styled.div`
${tw`z-[1]`}
.cta {
  ${tw`flex flex-row gap-4 items-center relative px-8 py-4 font-bold text-sm leading-4`}

  &:before {
    ${tw`[content: ''] w-16 h-full absolute left-0 top-0 rounded-3xl transition-all z-[-1]`}
  }

  &:hover {
    &:before {
      ${tw`w-full`}
    }
  }

  &.primary {
    ${tw`text-secondary`}
    &:before {
      ${tw`bg-primary`}
    }
    &:hover {
      ${tw`text-white`}
    }
    &.white {
      ${tw`text-white`}
    }
  }

  &.secondary {
    ${tw`text-white`}
    &:before {
      ${tw`bg-secondary`}
    }
    &:hover {
      ${tw`text-white`}
    }
  }
}
`;

export default StyledCta;

import React from "react"
import StyledDetails from "./style/details"
import CTA from "../common/CTA"
import Button from "../common/Button"
import { Link } from "gatsby"

const toKebabCase = str => {
  return str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join("-")
}

const Details = ({ data }) => {
  return (
    <StyledDetails>
      <div className="main-container">
        <h2>Openings at Cresol Infoserv</h2>
        <p className="uppercase">
          Positive pleasure-oriented goals are much more powerful motivators
          than negative fear-based ones.
        </p>
        <ul>
          {data.group &&
            data.group.map((d, i) => (
              <li className="info glassmorphism " key={i}>
                <h4>{d.fieldValue}</h4>
                <p>Hyderabad</p>
                {/* <CTA
                  link={`/${toKebabCase(d.fieldValue)}/`}
                  type={i === 1 ? "primary white" : "secondary"}
                  text="View Jobs"
                /> */}
                <Link to={`/${toKebabCase(d.fieldValue)}/`}>
                  <Button name="See Open Positions" />
                </Link>
              </li>
            ))}
        </ul>
      </div>
    </StyledDetails>
  )
}

export default Details

import tw, { styled } from "twin.macro"

const StyledDetails = styled.section`
  ${tw`md:py-16 py-6`};
  .main-container {
    ${tw`flex flex-col 2xl:px-[160px] sm:px-[50px] px-[10px] mx-auto z-[1] relative gap-x-16 items-center justify-center gap-4`}
    p {
      ${tw`font-medium`}
    }
    ul {
      ${tw`flex md:flex-row flex-col gap-6 w-full`}
      li {
        ${tw`md:w-1/3 w-full flex flex-col justify-center items-start p-8 border border-white/50 text-white rounded-md gap-4`}

        h4 {
          ${tw`md:text-2xl text-xl font-medium`}
        }

        /* &:nth-child(2) {
          ${tw`bg-secondary`}
        } */
      }
    }
  }
`

export default StyledDetails
